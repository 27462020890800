import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca certificate`}</strong>{` -- generate a new private key and certificate signed by the root certificate`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca certificate <subject> <crt-file> <key-file>
[--token=<token>]  [--issuer=<name>] [--provisioner-password-file=<file>]
[--not-before=<time|duration>] [--not-after=<time|duration>]
[--san=<SAN>] [--set=<key=value>] [--set-file=<file>]
[--acme=<file>] [--standalone] [--webroot=<file>]
[--contact=<email>] [--http-listen=<address>] [--bundle]
[--kty=<type>] [--curve=<curve>] [--size=<size>] [--console]
[--x5c-cert=<file>] [--x5c-key=<file>] [--k8ssa-token-path=<file>]
[--offline] [--password-file] [--ca-url=<uri>] [--root=<file>]
[--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca certificate`}</strong>{` command generates a new certificate pair`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`subject`}</inlineCode>{`
The Common Name, DNS Name, or IP address that will be set as the
Subject Common Name for the certificate. If no Subject Alternative Names (SANs)
are configured (via the --san flag) then the `}<inlineCode parentName="p">{`subject`}</inlineCode>{` will be set as the only SAN.`}</p>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
File to write the certificate (PEM format)`}</p>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
File to write the private key (PEM format)`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--san`}</strong>{`=`}<inlineCode parentName="p">{`dns|ip|email|uri`}</inlineCode>{`
Add `}<inlineCode parentName="p">{`dns|ip|email|uri`}</inlineCode>{` Subject Alternative Name(s) (SANs)
that should be authorized. Use the '--san' flag multiple times to configure
multiple SANs. The '--san' flag and the '--token' flag are mutually exclusive.`}</p>
    <p><strong parentName="p">{`--set`}</strong>{`=`}<inlineCode parentName="p">{`key=value`}</inlineCode>{`
The `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` pair with template data variables. Use the `}<strong parentName="p">{`--set`}</strong>{` flag multiple times to add multiple variables.`}</p>
    <p><strong parentName="p">{`--set-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The JSON `}<inlineCode parentName="p">{`file`}</inlineCode>{` with the template data variables.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--token`}</strong>{`=`}<inlineCode parentName="p">{`token`}</inlineCode>{`
The one-time `}<inlineCode parentName="p">{`token`}</inlineCode>{` used to authenticate with the CA in order to create the
certificate.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <p><strong parentName="p">{`--provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use.`}</p>
    <p><strong parentName="p">{`--provisioner-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--kty`}</strong>{`=`}<inlineCode parentName="p">{`kty`}</inlineCode>{`
The `}<inlineCode parentName="p">{`kty`}</inlineCode>{` to build the certificate upon.
If unset, default is EC.`}</p>
    <p><inlineCode parentName="p">{`kty`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`EC`}</strong>{`: Create an `}<strong parentName="p">{`elliptic curve`}</strong>{` keypair`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`OKP`}</strong>{`: Create an octet key pair (for `}<strong parentName="p">{`"Ed25519"`}</strong>{` curve)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA`}</strong>{`: Create an `}<strong parentName="p">{`RSA`}</strong>{` keypair`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--crv`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`, `}<strong parentName="p">{`--curve`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`
The elliptic `}<inlineCode parentName="p">{`curve`}</inlineCode>{` to use for EC and OKP key types. Corresponds
to the `}<strong parentName="p">{`"crv"`}</strong>{` JWK parameter. Valid curves are defined in JWA `}{`[RFC7518]`}{`. If
unset, default is P-256 for EC keys and Ed25519 for OKP keys.`}</p>
    <p><inlineCode parentName="p">{`curve`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-256`}</strong>{`: NIST P-256 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-384`}</strong>{`: NIST P-384 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-521`}</strong>{`: NIST P-521 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ed25519`}</strong>{`: Ed25519 Curve`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--size`}</strong>{`=`}<inlineCode parentName="p">{`size`}</inlineCode>{`
The `}<inlineCode parentName="p">{`size`}</inlineCode>{` (in bits) of the key for RSA and oct key types. RSA keys require a
minimum key size of 2048 bits. If unset, default is 2048 bits for RSA keys and 128 bits for oct keys.`}</p>
    <p><strong parentName="p">{`--not-after`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period ends. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h".`}</p>
    <p><strong parentName="p">{`--not-before`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period starts. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h".`}</p>
    <p><strong parentName="p">{`--attestation-uri`}</strong>{`=`}<inlineCode parentName="p">{`uri`}</inlineCode>{`
The KMS `}<inlineCode parentName="p">{`uri`}</inlineCode>{` used for attestation.`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--console`}</strong>{`
Complete the flow while remaining inside the terminal`}</p>
    <p><strong parentName="p">{`--kms`}</strong>{`=`}<inlineCode parentName="p">{`uri`}</inlineCode>{`
The `}<inlineCode parentName="p">{`uri`}</inlineCode>{` to configure a Cloud KMS or an HSM.`}</p>
    <p><strong parentName="p">{`--x5c-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'x5c' header of a JWT.`}</p>
    <p><strong parentName="p">{`--x5c-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the certificate that will
be stored in the 'x5c' header.`}</p>
    <p><strong parentName="p">{`--x5c-chain`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{`, in PEM format`}</p>
    <p><strong parentName="p">{`--nebula-cert`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{` in PEM format to store in the 'nebula' header of a JWT.`}</p>
    <p><strong parentName="p">{`--nebula-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the certificate that will
be stored in the 'nebula' header.`}</p>
    <p><strong parentName="p">{`--acme`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
ACME directory `}<inlineCode parentName="p">{`url`}</inlineCode>{` to be used for requesting certificates via the ACME protocol.
Use this flag to define an ACME server other than the Step CA. If this flag is
absent and an ACME provisioner has been selected then the '--ca-url' flag must be defined.`}</p>
    <p><strong parentName="p">{`--standalone`}</strong>{`
Get a certificate using the ACME protocol and standalone mode for validation.
Standalone is a mode in which the step process will run a server that will
will respond to ACME challenge validation requests. Standalone is the default
mode for serving challenge validation requests.`}</p>
    <p><strong parentName="p">{`--webroot`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Specify a `}<inlineCode parentName="p">{`file`}</inlineCode>{` to use as a 'web root' for validation in the ACME protocol.
Webroot is a mode in which the step process will write a challenge file to a
location being served by an existing fileserver in order to respond to ACME
challenge validation requests.`}</p>
    <p><strong parentName="p">{`--contact`}</strong>{`=`}<inlineCode parentName="p">{`email-address`}</inlineCode>{`
The `}<inlineCode parentName="p">{`email-address`}</inlineCode>{` used for contact as part of the ACME protocol. These contacts
may be used to warn of certificate expiration or other certificate lifetime events.
Use the '--contact' flag multiple times to configure multiple contacts.`}</p>
    <p><strong parentName="p">{`--http-listen`}</strong>{`=`}<inlineCode parentName="p">{`address`}</inlineCode>{`
Use a non-standard http `}<inlineCode parentName="p">{`address`}</inlineCode>{`, behind a reverse proxy or load balancer, for
serving ACME challenges. The default address is :80, which requires super user
(sudo) privileges. This flag must be used in conjunction with the '--standalone'
flag.`}</p>
    <p><strong parentName="p">{`--k8ssa-token-path`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Configure the `}<inlineCode parentName="p">{`file`}</inlineCode>{` from which to read the kubernetes service account token.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Request a new certificate for a given domain. There are no additional SANs
configured, therefore (by default) the `}<inlineCode parentName="p">{`subject`}</inlineCode>{` will be used as the only
SAN extension: DNS Name internal.example.com:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ TOKEN=$(step ca token internal.example.com)
$ step ca certificate --token $TOKEN internal.example.com internal.crt internal.key
`}</code></pre>
    <p>{`Request a new certificate with multiple Subject Alternative Names. The Subject
Common Name of the certificate will be 'foobar'. However, because additional SANs are
configured using the --san flag and 'foobar' is not one of these, 'foobar' will
not be in the SAN extensions of the certificate. The certificate will have 2
IP Address extensions (1.1.1.1, 10.2.3.4) and 1 DNS Name extension (hello.example.com):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate --san 1.1.1.1 --san hello.example.com --san 10.2.3.4 foobar internal.crt internal.key
`}</code></pre>
    <p>{`Request a new certificate with a 1h validity:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ TOKEN=$(step ca token internal.example.com)
$ step ca certificate --token $TOKEN --not-after=1h internal.example.com internal.crt internal.key
`}</code></pre>
    <p>{`Request a new certificate using the offline mode, requires the configuration
files, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate --offline internal.example.com internal.crt internal.key
`}</code></pre>
    <p>{`Request a new certificate using the offline mode with additional flags to avoid
console prompts:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate --offline \\
  --password-file ./pass.txt \\
  --provisioner foo \\
  --provisioner-password-file ./provisioner-pass.txt \\
  internal.example.com internal.crt internal.key
`}</code></pre>
    <p>{`Request a new certificate using an OIDC provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate --token $(step oauth --oidc --bare) joe@example.com joe.crt joe.key
`}</code></pre>
    <p>{`Request a new certificate using an OIDC provisioner while remaining in the console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate joe@example.com joe.crt joe.key --issuer Google --console
`}</code></pre>
    <p>{`Request a new certificate with an RSA public key (default is ECDSA256):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate foo.internal foo.crt foo.key --kty RSA --size 4096
`}</code></pre>
    <p>{`Request a new certificate with an X5C provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate foo.internal foo.crt foo.key --x5c-cert x5c.cert --x5c-key x5c.key
`}</code></pre>
    <p><strong parentName="p">{`Certificate Templates`}</strong>{` - With a provisioner configured with a custom
template we can use the `}<strong parentName="p">{`--set`}</strong>{` flag to pass user variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate foo.internal foo.crt foo.key --set emailAddresses=root@internal.com
$ step ca certificate foo.internal foo.crt foo.key --set emailAddresses='["foo@internal.com","root@internal.com"]'
`}</code></pre>
    <p>{`Or you can pass them from a file using `}<strong parentName="p">{`--set-file`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat path/to/data.json
{
  "emailAddresses": ["foo@internal.com","root@internal.com"]
}
$ step ca certificate foo.internal foo.crt foo.key --set-file path/to/data.json
`}</code></pre>
    <p><strong parentName="p">{`step CA ACME`}</strong>{` - In order to use the step CA ACME protocol you must add a
ACME provisioner to the step CA config. See `}<strong parentName="p">{`step ca provisioner add -h`}</strong>{`.`}</p>
    <p>{`Request a new certificate using the step CA ACME server and a standalone server
to serve the challenges locally (standalone mode is the default):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate foobar foo.crt foo.key --provisioner my-acme-provisioner --san foo.internal --san bar.internal
`}</code></pre>
    <p>{`Request a new certificate using the step CA ACME server and an existing server
along with webroot mode to serve the challenges locally:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate foobar foo.crt foo.key --provisioner my-acme-provisioner --webroot "./acme-www" \\
--san foo.internal --san bar.internal
`}</code></pre>
    <p>{`Request a new certificate using the ACME protocol not served via the step CA
(e.g. letsencrypt). NOTE: Let's Encrypt requires that the Subject Common Name
of a requested certificate be validated as an Identifier in the ACME order along
with any other SANS. Therefore, the Common Name must be a valid DNS Name. The
step CA does not impose this requirement.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate foo.internal foo.crt foo.key \\
--acme https://acme-staging-v02.api.letsencrypt.org/directory --san bar.internal
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      